import './Text.css';

function Text() {

	const text = [
		{ id: 0, text: 'Предоставление автокрана Liebherr LR 1750 и автотрейлеров из собственного парка компании «Казметрострой».Техника использовалась для демонтажа и перевозки проходческих щитов' },
		{ id: 1, text: 'Участие автокрана Liebherr LR 1750 и автотрейлеров в демонтаже проходческого щита на участке метрополитена от станции «Выхино» до «Косино-Ухтомской».' },
		{ id: 2, text: 'Использование Liebherr LTM 1500 в качестве основной мощности для производства работ при возведении Лукомльской ГРЭС. В качестве арендатора выступала китайская машиностроительная инжиниринговая компания.' },
		{ id: 3, text: 'Аренда низкорамных тралов для транспортировки спецтехники официального дилера ТМ в России, компании ООО «Либхерр-Русланд».' },
		{ id: 4, text: 'Установка нового реактора гидрокрекинга на территории завода ОАО «Уфанефтехим».' },
		{ id: 5, text: 'Демонтаж старых и монтаж новых чиллеров на крыше здания Mail.ru. Арендатор — строительный холдинг КМТ.' },
		{ id: 6, text: 'Монтаж мостовых балок на головном участке автомобильной скоростной дороги Москва-Санкт-Петербург, произведенный с использованием кранов Liebherr.' },
		{ id: 7, text: 'Транспортировка и установка проходческого щита из Протвино в Москву для возведения Бутовской линии метрополитена. Заказ выполнен компанией «Транстоннельстрой».' },
	]

	return (
		<section className='text'>

			<div className='text__content'>

				<h2 className='text__title'>
					Аренда автокранов LIEBHERR в Москве
				</h2>

				<p className='text__subtitle'>
					Аренда автокранов решает разные задачи.
					Благодаря большой грузоподъемности техника используется при установке и разборке башенных кранов,
					строительстве мостовых пролетов, монтаже дымовых труб самонесущей конструкции,
					выполнении аварийно-восстановительных работ в Москве.
				</p>

				<h2 className='text__title'>
					Аренда спецтехники Liebherr
				</h2>

				<p className='text__subtitle'>
					Liebherr («Либхерр») — известная немецкая машиностроительная компания.
					Ее автокраны востребованы во всем мире благодаря своим уникальным возможностям.
					Основополагающие критерии их выбора — скорость и качество работы.
					Крупные строительные компании и частные лица предпочитают арендовать строительные краны «Либхерр».
					Их выбор обусловлен надежностью, высокой производительностью установок.
					Срок службы агрегатов исчисляется десятилетиями, на протяжении которых они сохраняют свои характеристики и не теряют качества.
				</p>

				<p className='text__subtitle'>
					На сегодняшний день мы реализовали сотни успешных проектов. Среди них:
				</p>

				<ul className='text__list'>
					{
						text.map((item) => {
							return (
								<li className='text__list-item' key={item.id}>
									<p className='text__list-item-text'>
										{item.text}
									</p>
								</li>
							)
						})
					}
				</ul>

				<p className='text__subtitle'>
					Условия аренды оговариваются индивидуально.
					География предоставления услуги — Москва, Санкт-Петербург, Екатеринбург, Нижний Новгород,
					Пермь, Воронеж, Челябинск, Краснодар, Ярославль, Самара, Уфа и другие города РФ.
				</p>

			</div>

		</section>
	)
}

export default Text;